import './App.css'

import React, { Suspense } from 'react'
import { ReactQueryDevtools } from 'react-query/devtools'
import { Navigate, Route, Routes } from 'react-router-dom'
import { SuccessBannerProvider } from '@aserto/console-common'

import { NavBar } from './components/NavBar'
import CreateOrganizationModal from './components/tenants/CreateOrganizationModal'
import { useProfile } from './services/ProfileAndQueryClientProvider'

const AccountSettings = React.lazy(() => import('./views/AccountSettings'))
const Connections = React.lazy(() => import('./views/Connections'))
const CreateTenant = React.lazy(() => import('./views/CreateTenant'))
const DirectoryBrowser_v2 = React.lazy(() => import('./views/DirectoryBrowser_v2'))
const Error = React.lazy(() => import('./views/Error'))
const InvitationDetails = React.lazy(() => import('./views/InvitationDetails'))
const Logout = React.lazy(() => import('./views/Logout'))
const ManageTenant = React.lazy(() => import('./views/ManageTenant'))
const UserView = React.lazy(() => import('./views/UserView'))
const Evaluator = React.lazy(() => import('./views/Evaluator'))
const Model = React.lazy(() => import('./views/Model'))
const Playground = React.lazy(() => import('./views/Playground'))

function App() {
  const { tenant } = useProfile()

  return (
    <div id="app">
      <NavBar />
      <CreateOrganizationModal />
      <SuccessBannerProvider>
        <Suspense fallback={<></>}>
          <Routes>
            <Route element={<Logout />} path="/logout" />
            <Route element={<Error />} path="/error" />
            <Route element={<Model />} path="/ui/model/*" />
            <Route element={<Evaluator />} path="/ui/evaluator" />
            <Route element={<Playground />} path="/ui/playground" />
            <Route element={<Connections />} path="/ui/connections" />
            <Route element={<UserView />} path="/ui/directory/:id" />
            <Route element={<CreateTenant />} path="/ui/createtenant" />
            <Route element={<DirectoryBrowser_v2 />} path="/ui/directory_v2/*" />

            {tenant?.personal === false && [
              <Route key="/ui/manage-tenant" element={<ManageTenant />} path="/ui/manage-tenant" />,
            ]}
            <Route element={<AccountSettings />} path="/ui/account-settings" />
            <Route element={<InvitationDetails />} path="/ui/invitation-details/:id" />
            <Route element={<InvitationDetails />} path="/ui/invitation-details" />
            <Route element={<Navigate replace to="/ui/model" />} path="*" />
          </Routes>
        </Suspense>
      </SuccessBannerProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </div>
  )
}

export default App
