import React, { PropsWithChildren } from 'react'
import { InternalConfigProvider } from '@aserto/console-common'

import { useCentralDirectoryConnection } from '../../api/connections'
import { useConfig } from '../ConfigProvider'

const ConsoleCommonProvider: React.FC<PropsWithChildren<{}>> = ({ children }) => {
  const config = useConfig()
  const { data: directoryConnection } = useCentralDirectoryConnection()
  const directoryApiKey = directoryConnection?.result?.config?.api_key_read as string

  return !config ? null : (
    <InternalConfigProvider
      config={{
        ...config,
        directoryApiKey,
      }}
    >
      {children}
    </InternalConfigProvider>
  )
}

export default ConsoleCommonProvider
