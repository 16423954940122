import { useEffect, useRef } from 'react'

export const redirectUsingWindow = (url: string) => {
  window.location.assign(url)
}

export const useInputFocusRef = (dependencies: Array<unknown>) => {
  const inputRef = useRef<HTMLInputElement>(null)
  useEffect(() => {
    if (inputRef?.current) {
      inputRef?.current?.focus()
    }
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ...dependencies,
    inputRef,
  ])
  return inputRef
}
