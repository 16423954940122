import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, CardModal, CardText, Input } from '@aserto/console-common'
import { SpinnerToggle } from '@aserto/console-common'

import { useClaimTenant, useValidateTenantName } from '../../api/onboarding'
import { getVisualState } from '../../components/tenants/GetVisualState'
import { VisualStates } from '../../components/tenants/VisualStates'
import AccountNameValidator from '../../lib/validation/AccountNameValidator'
import { useIdentity, User } from '../../services/IdentityProvider'
import { useProfile } from '../../services/ProfileAndQueryClientProvider'
import { ButtonContainer, CardTextUpper, CreateTenantContainer, InputContainer } from './styles'

const getInitialAccountName = (value: string) => {
  const name = value
    .toLowerCase()
    .replace(/[^a-zA-Z ]/g, '')
    .replace(/\s/g, '')

  return AccountNameValidator.isValid(name) ? name : ''
}

const getFirstName = (user: User | undefined) => {
  if (!user) {
    return ''
  }

  if (user.given_name) {
    return user.given_name
  }
  if (user.nickname) {
    return user.nickname.split(/[^A-Za-z]/)[0]
  }
  if (user.name) {
    return user.name.split(' ')[0]
  }
  return user.email
}

const CreateTenant = () => {
  const navigate = useNavigate()
  const { account, setTenantId } = useProfile()

  useEffect(() => {
    // tenant name can never be reset
    if (account?.personal_tenant) {
      navigate('/')
    }
  }, [account, navigate])

  const { user } = useIdentity()
  const [accountName, setAccount] = useState('')
  const [previousVisualState, setPreviousVisualState] = useState(VisualStates.VALID_FRAGMENT)

  useEffect(() => {
    if (!!user && user.nickname) {
      setAccount(getInitialAccountName(user.nickname))
    }
  }, [user])

  const claimTenant = useClaimTenant({
    onSuccess: ({ id }) => setTenantId(String(id), '/ui/invitation-details'),
  })
  const validateTenantName = useValidateTenantName(accountName)
  const visualState = getVisualState(
    previousVisualState,
    accountName,
    validateTenantName,
    claimTenant
  )

  useEffect(() => {
    if (visualState.kind === VisualStates.CLAIMING_FAILED.kind) {
      claimTenant.reset()
    }
  }, [claimTenant, visualState.kind])

  useEffect(() => setPreviousVisualState(visualState), [visualState])

  const storeAccount = async () =>
    claimTenant.mutate({ name: accountName, personal: true, default_artifacts: true })

  const { errorMessage, isClaimable, isEditable, isLoading, isUnavailable, isValid } = visualState

  const firstName = getFirstName(user)
  const welcomeMessage = firstName ? `Welcome to Aserto, ${firstName}!` : 'Welcome to Aserto!'

  return (
    <CreateTenantContainer paddingTop={83}>
      <CardModal
        cardHeight={430}
        show
        text={
          <>
            <CardTextUpper>{welcomeMessage}</CardTextUpper>
            <CardText isInModal>
              Your account name will be part of the namespace that will identify your policy
              repositories, much like your GitHub account is used to namespace your source code
              repositories.
            </CardText>
          </>
        }
        title="Choose an account name"
      >
        <InputContainer>
          <Input
            data-testid="tenant-name-input"
            disabled={!isEditable}
            error={errorMessage}
            info="Start with a letter. Must be 4 to 30 lowercase alphanumeric characters or dashes."
            isUnavailable={isUnavailable}
            isValid={isValid}
            label="Account name"
            value={accountName}
            onChange={(e) => setAccount(e.target.value)}
          />
          <ButtonContainer>
            <Button disabled={!isClaimable} variant="primary" onClick={storeAccount}>
              Lock it in
            </Button>
          </ButtonContainer>
        </InputContainer>
      </CardModal>
      <SpinnerToggle show={isLoading} />
    </CreateTenantContainer>
  )
}

export default CreateTenant
