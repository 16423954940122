import React, { useCallback, useEffect, useMemo, useRef } from 'react'
import { Dropdown, Nav, NavItem } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { NavLink, useNavigate } from 'react-router-dom'
import {
  AsertoNavBar,
  ReactSelectElement,
  Select,
  SelectOption,
  theme,
  UndecoratedLink,
} from '@aserto/console-common'

import { useAccountInvites } from '../../api/members'
import AsertoLogo from '../../assets/aserto-horizontal-white-text.svg'
import separator from '../../assets/separator.svg'
import { useIdentity } from '../../services/IdentityProvider'
import { useProfile } from '../../services/ProfileAndQueryClientProvider'
import { AccountTenantInvite } from '../../types/local/tenant'
import { useDispatchContext } from '../../ui/state/context/DispatchContext'
import { isInviteAcceptable } from '../../utils/invites'
import ApiDocsDropdownItem from './ApiDocsDropdownItem'
import { DropdownMenu, ImgBadge, StyledDropdown, TenantInputContainer } from './styles'

export const NavBar = () => {
  const dispatch = useDispatchContext()
  const navigate = useNavigate()
  const { logout, user } = useIdentity()
  const tenantSelectRef = useRef<ReactSelectElement>(null)
  const { account, setTenantId, tenant } = useProfile()
  const { data: accountInvitesData } = useAccountInvites()

  const acceptableInvites = useMemo(() => {
    const invites: Array<AccountTenantInvite> = accountInvitesData?.results ?? []
    return invites.filter(isInviteAcceptable)
  }, [accountInvitesData?.results])

  const tenants: Array<SelectOption> = useMemo(() => {
    return (
      account?.tenants
        ?.sort((tenant, otherTenant) => {
          if (tenant.personal && !otherTenant.personal) {
            return -1
          }

          if (!tenant.personal && otherTenant.personal) {
            return 1
          }

          return tenant.name!.localeCompare(otherTenant.name!)
        })
        .map((tenant) => ({
          value: tenant.id!,
          label: tenant.personal ? `${tenant.name!} (account)` : tenant.name!,
        })) ?? []
    )
  }, [account?.tenants])

  const options = useMemo(() => {
    if (account?.tenants?.length === 1) {
      return [
        {
          label: '',
          options: [
            {
              label: 'Create an organization',
              value: 'create',
              shouldStopPropagation: true,
              onClick: () => {
                dispatch({ type: 'SET_CURRENT_MODAL', modal: { type: 'CREATE_ORGANIZATION' } })
              },
            },
          ],
        },
      ]
    }
    return [
      {
        options: [
          {
            label: 'Create an organization',
            value: 'create',
            shouldStopPropagation: true,
            onClick: () => {
              dispatch({ type: 'SET_CURRENT_MODAL', modal: { type: 'CREATE_ORGANIZATION' } })
            },
          },
          ...(!tenant?.personal
            ? [
                {
                  label: 'Manage organization',
                  value: 'manage',
                  shouldStopPropagation: true,
                  onClick: () => navigate('/ui/manage-tenant'),
                },
              ]
            : []),
        ],
      },
      {
        label: 'Organizations',
        options: tenants,
      },
    ]
  }, [account?.tenants?.length, dispatch, navigate, tenant?.personal, tenants])

  useEffect(() => {
    if (tenant?.id) {
      tenantSelectRef?.current?.setValue(
        tenants.find((option: SelectOption) => option?.value === tenant?.id)!,
        'select-option'
      )
    }
  }, [tenant, tenants])

  const onChangeTenantId = useCallback(
    (data: SelectOption | null) => {
      if (data?.value && data?.value !== tenant?.id) {
        setTenantId(String(data.value))
      }
    },
    [setTenantId, tenant?.id]
  )

  return (
    <AsertoNavBar
      expand="xl"
      logo={
        <a href="/">
          <img src={AsertoLogo} />
        </a>
      }
      uncollapsableContent={
        <TenantInputContainer>
          <Select
            ref={tenantSelectRef}
            defaultValue={tenants?.find((option: SelectOption) => option?.value === tenant?.id)}
            formatGroupLabel={(a) => {
              return !!a?.label ? (
                <div
                  style={{
                    alignItems: 'center',
                    borderBottom: '1px #414141 solid',
                    borderTop: '1px #414141 solid',
                    color: theme.grey100,
                    display: 'flex',
                    fontSize: 14,
                    fontWeight: 'bold',
                    height: '100%',
                    minHeight: '36px',
                  }}
                >
                  {a.label}
                </div>
              ) : undefined
            }}
            isSearchable={false}
            modifyCustomStyle={(style) => {
              if (!style.groupHeading) {
                return {}
              }
              return {
                ...style,
                groupHeading: (styles) => {
                  return {
                    ...styles,
                    backgroundColor: '#2a2a2a',
                    margin: 0,
                  }
                },
              }
            }}
            options={options}
            placeholder="Select tenant"
            style={{
              width: '100%',
              height: 35,
            }}
            onChange={onChangeTenantId}
          />
          <img alt="separator" src={separator} />
        </TenantInputContainer>
      }
    >
      <Nav as="ul" className="mr-auto">
        <>
          <NavItem>
            <NavLink
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              to="/ui/model"
            >
              Model
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              to="/ui/directory_v2"
            >
              Directory
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              to="/ui/evaluator"
            >
              Evaluator
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              to="/ui/playground"
            >
              Playground
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={({ isActive }) => (isActive ? 'nav-link active' : 'nav-link')}
              to="/ui/connections"
            >
              Connections
            </NavLink>
          </NavItem>
        </>
        <Nav.Item as="li" className="d-lg-none d-xl-none d-md-block">
          <LinkContainer to="/ui/quickstarts">
            <Nav.Link>Quickstarts</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item as="li" className="d-lg-none d-xl-none d-md-block">
          <LinkContainer to="/ui/account-settings">
            <Nav.Link>Account settings</Nav.Link>
          </LinkContainer>
        </Nav.Item>
        <Nav.Item as="li" className="d-lg-none d-xl-none d-md-block">
          <Nav.Link onClick={logout}>Sign out</Nav.Link>
        </Nav.Item>
      </Nav>
      <Nav className="d-none d-md-block">
        <StyledDropdown className="d-md-none d-lg-block">
          <Dropdown.Toggle as={Nav.Link} className="toggle-profile position-relative">
            {acceptableInvites.length > 0 && <ImgBadge />}
            <img
              alt="Profile"
              className="nav-user-profile rounded-circle"
              height="41"
              src={user?.picture}
              width="41"
            />
          </Dropdown.Toggle>
          <DropdownMenu align="right">
            <Dropdown.Header>{user?.name}</Dropdown.Header>
            <UndecoratedLink to="/ui/account-settings">
              <Dropdown.Item as="li">
                <i className="fa fa-user mr-3" /> Account settings
              </Dropdown.Item>
            </UndecoratedLink>

            <UndecoratedLink to="/ui/quickstarts">
              <Dropdown.Item as="li">
                <i className="fa fa-play mr-3" /> Quickstarts
              </Dropdown.Item>
            </UndecoratedLink>
            {tenant !== null && <ApiDocsDropdownItem tenant={tenant} />}
            <Dropdown.Item id="qsLogoutBtn" onClick={logout}>
              <i className="fa fa-power-off mr-3" /> Sign out
            </Dropdown.Item>
          </DropdownMenu>
        </StyledDropdown>
      </Nav>
    </AsertoNavBar>
  )
}
