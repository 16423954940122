import { AccountTenantInvite } from '../types/local/tenant'

export const isInviteAcceptable = (invite: AccountTenantInvite) => {
  //TODO: update this after tenant is deployed to production

  return (
    invite?.invite?.status === 'INVITE_STATUS_ACTIVE' ||
    invite?.invite?.status === 'INVITE_STATUS_UNKNOWN'
  )
}
