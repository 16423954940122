import { useAsertoApiWithAuth0Token, v1Api } from '@aserto/console-common'

import { useConfig } from '../services/ConfigProvider'

export function useOnboardingClient() {
  const { tenantServiceUrl } = useConfig()
  const onboardingApi = `${v1Api(tenantServiceUrl)}/onboarding`

  return useAsertoApiWithAuth0Token(onboardingApi)
}
