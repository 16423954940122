import { useMemo } from 'react'
import { useAsertoApiWithAuth0Token, v1Api } from '@aserto/console-common'

import { useConfig } from '../services/ConfigProvider'
import { useIdentity } from '../services/IdentityProvider'

export function useHubSpotClient() {
  const { backendServiceUrl } = useConfig()
  const backendServiceApiUrl = v1Api(backendServiceUrl)
  const { user } = useIdentity()
  const { post } = useAsertoApiWithAuth0Token(backendServiceApiUrl)
  return useMemo(
    () => ({
      hubspotCreateContact: async <Tin>(
        data: Tin,
        headerOverrides: Record<string, string> = {}
      ) => {
        return !!user
          ? await post({ body: data, headerOverrides, path: 'hubspot/createcontact' })
          : {}
      },
      hubspotSetProperty: async <Tin>(data: Tin, headerOverrides: Record<string, string> = {}) => {
        return !!user
          ? await post({
              body: { email: user.email, ...data },
              headerOverrides,
              path: 'hubspot/setproperty',
            })
          : {}
      },
    }),
    [post, user]
  )
}
