import 'bootstrap/dist/css/bootstrap.css'
import 'font-awesome/css/font-awesome.min.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import { AsertoProvider } from '@aserto/aserto-react'
import {
  DirectoryEvaluatorContextProvider,
  ErrorModalProvider,
  SpinnerProvider,
  theme,
} from '@aserto/console-common'

import App from './App'
import OAuthConnectionProvider from './components/OAuthConnectionProvider'
import { GlobalStyle } from './globalStyles'
import reportWebVitals from './reportWebVitals'
import Auth0IdentityProvider from './services/Auth0IdentityProvider'
import CachePreloader from './services/CachePreloader'
import ConfigProvider from './services/ConfigProvider'
import ConsoleCommonProvider from './services/ConsoleCommonProvider'
import FullStoryIdentify from './services/FullStory/FullStoryIdentify'
import FullStoryInit from './services/FullStory/FullStoryInit'
import OfflineBanner from './services/OfflineBanner'
import PendoInit from './services/Pendo/PendoInit'
import ProfileAndQueryClientProvider from './services/ProfileAndQueryClientProvider'
import SentryEmbed from './services/SentryEmbed'
import ServiceInfoProvider from './services/ServiceInfoProvider'
import SmallScreenErrorModal from './services/SmallScreenErrorModal'
import { AppStateContextProvider } from './ui/state/context'

const queryClient = new QueryClient()

const container = document.getElementById('root')
const root = createRoot(container!)
root.render(
  <>
    <SmallScreenErrorModal />
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <SpinnerProvider>
            <OfflineBanner />
            <ErrorModalProvider>
              <Auth0IdentityProvider>
                <ConfigProvider>
                  <FullStoryInit />
                  <SentryEmbed />
                  <ServiceInfoProvider>
                    <FullStoryIdentify />
                    <AsertoProvider>
                      <ProfileAndQueryClientProvider>
                        <CachePreloader>
                          <PendoInit disabled />
                          <AppStateContextProvider>
                            <OAuthConnectionProvider>
                              <CachePreloader block>
                                <ConsoleCommonProvider>
                                  <DirectoryEvaluatorContextProvider>
                                    <App />
                                  </DirectoryEvaluatorContextProvider>
                                </ConsoleCommonProvider>
                              </CachePreloader>
                            </OAuthConnectionProvider>
                          </AppStateContextProvider>
                        </CachePreloader>
                      </ProfileAndQueryClientProvider>
                    </AsertoProvider>
                  </ServiceInfoProvider>
                </ConfigProvider>
              </Auth0IdentityProvider>
            </ErrorModalProvider>
          </SpinnerProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  </>
)
reportWebVitals()
