import { useAsertoApiWithAuth0Token } from '@aserto/console-common'

import { useEnvConfig } from '../services/EnvConfigProvider'

export function useConfigClient() {
  const envConfig = useEnvConfig()
  const discoveryServiceUrl = envConfig.isDocker
    ? window.location.origin
    : envConfig.discoveryServiceUrl
  return useAsertoApiWithAuth0Token(`${discoveryServiceUrl}/api/v1`)
}
