// This is the project's abstraction around the history APIs, so disabling protection
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { createBrowserHistory, History } from 'history'
// This is the project's abstraction around the history APIs, so disabling protection
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
// export { createMemoryHistory } from 'history'
// This is the project's abstraction around the history APIs, so disabling protection
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import React, { useContext } from 'react'

type HistoryProviderContextProps = {
  history: History
}

const historyContext = React.createContext<HistoryProviderContextProps>({
  history: createBrowserHistory(),
})

export const useHistory = () => {
  return useContext(historyContext).history
}
