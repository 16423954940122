import React, { useCallback, useEffect, useState } from 'react'
import { Button, Input } from '@aserto/console-common'
import { SpinnerToggle } from '@aserto/console-common'

import { useClaimTenant, useValidateTenantName } from '../../../api/onboarding'
import { useProfile } from '../../../services/ProfileAndQueryClientProvider'
import { useCurrentModalContext } from '../../../ui/state/context/CurrentModalContext'
import { useDispatchContext } from '../../../ui/state/context/DispatchContext'
import { getVisualState } from '../GetVisualState'
import Modal from '../Modal'
import { VisualStates } from '../VisualStates'
import OrgModalImage from './OrgModalImage.svg'
import { AccentText, StyledSectionHeader } from './styles'

const CreateOrganizationModal = () => {
  const dispatch = useDispatchContext()
  const currentModal = useCurrentModalContext()

  const [organizationName, setOrganizationName] = useState('')

  const show = currentModal?.type === 'CREATE_ORGANIZATION'

  const [previousVisualState, setPreviousVisualState] = useState(VisualStates.VALID_FRAGMENT)
  const { invalidateAccountCache, setTenantId } = useProfile()
  const [newTenantId, setNewTenantId] = useState('')
  const clearModalState = () => {
    setOrganizationName('')
  }
  const claimTenant = useClaimTenant({
    onSuccess: ({ id }) => {
      setNewTenantId(String(id))
      invalidateAccountCache()
    },
    onError: () => {
      clearModalState()
    },
  })

  const tenantNameValidationQueryResult = useValidateTenantName(organizationName)

  const visualState = getVisualState(
    previousVisualState,
    organizationName,
    tenantNameValidationQueryResult,
    claimTenant
  )

  useEffect(() => {
    if (visualState.kind === VisualStates.CLAIMING_FAILED.kind) {
      claimTenant.reset()
    }
  }, [claimTenant, visualState.kind])

  useEffect(() => setPreviousVisualState(visualState), [visualState])

  const { errorMessage, isClaimable, isEditable, isLoading, isUnavailable, isValid } = visualState

  const onNameChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setOrganizationName(event.target.value)
  }, [])

  const onCancel = useCallback(() => {
    dispatch({ type: 'SET_CURRENT_MODAL', modal: null })
  }, [dispatch])

  const createOrganization = useCallback(() => {
    claimTenant.mutateAsync({ name: organizationName })
  }, [claimTenant, organizationName])

  const gotIt = useCallback(() => {
    setTenantId(newTenantId)
    dispatch({ type: 'SET_CURRENT_MODAL', modal: null })
  }, [dispatch, newTenantId, setTenantId])

  return (
    <>
      <Modal centered show={show && !newTenantId} onHide={onCancel}>
        <Modal.Header>
          <Modal.Title>Create an organization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StyledSectionHeader>Organizations require a paid plan</StyledSectionHeader>
          <p>
            You may trial the organizations feature for 30 days. Beyond this, you will need to get a
            paid plan. Your personal account will remain free forever.
          </p>
          <Input
            disabled={!isEditable}
            error={organizationName && errorMessage}
            info="Start with a letter. Must be 4 to 30 alphanumeric characters or dashes."
            isUnavailable={isUnavailable}
            isValid={isValid}
            label="Organization name"
            value={organizationName}
            onChange={onNameChange}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button disabled={!isClaimable} onClick={createOrganization}>
            Lock it in!
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={show && !!newTenantId} onHide={onCancel}>
        <Modal.Header variant="success">
          <Modal.Title>Success!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your new organization, <AccentText>"{organizationName}"</AccentText>, has been created!
            You can easily switch organizations using the dropdown menu in the top left of every
            screen.
          </p>
          <p>
            You can also invite and manage team members by choosing the "Manage organization" menu
            item.
          </p>
          <img src={OrgModalImage} width="100%" />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={gotIt}>
            Switch to new organization
          </Button>
        </Modal.Footer>
      </Modal>
      <SpinnerToggle show={isLoading} />
    </>
  )
}

export default CreateOrganizationModal
